<template>
  <router-view  v-if="URLsecure"/>

  <q-dialog
      v-model="dialog"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card class="bg-primary text-white">
        <q-bar>
          <q-space />

          <q-btn dense flat icon="minimize" @click="maximizedToggle = false" :disable="!maximizedToggle">
            <q-tooltip v-if="maximizedToggle" class="bg-white text-primary">Minimize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="crop_square" @click="maximizedToggle = true" :disable="maximizedToggle">
            <q-tooltip v-if="!maximizedToggle" class="bg-white text-primary">Maximize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <div class="text-h3">Notificación</div>
        </q-card-section>
        <q-separator spaced />

        <q-card-section id="texto" class="q-pt-none">
          <q-icon name="warning" size="5rem" /> <br>
          La conexión está bajo protocolo no seguro HTTP. <br>
          Se muestra esta advertencia porque este sitio solo admite HTTPS. <br>
          para continuar bajo protocolo seguro presiona el botón REDIRECCIONAR <br>
          
          <q-btn class="q-mt-md full-width" color="dark" href="https://tecnicosinmalec.delmondexpress.com" icon="link" label="Redireccionar"/>
          
        </q-card-section>
      </q-card>
    </q-dialog>


  
</template>


<script>
import { useQuasar } from 'quasar';
import {ref} from 'vue';

export default {

  setup() {
    const $q = useQuasar()
    const URLactual = window.location.href;
    const URLsecure = ref(true);
    const dialog= ref(false);
    const maximizedToggle= ref(true)
    const verificarURL = ()=>{
      if(!URLactual.includes('https:')){        
        if(!URLactual.includes('http://localhost') && !URLactual.includes('http://192.168')){
          dialog.value = true;
          URLsecure.value = false;
        }
      }
    }

    verificarURL()

    return {
      dialog,
      maximizedToggle,
      URLsecure
        
    };
  },
};
</script>
<style lang="scss" scoped>
#texto{
  font-size: 20px;
  text-align: justify;
}
</style>>


